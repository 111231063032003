import * as styles from './Help.module.scss'

import React from 'react'
import { useRecoilState } from 'recoil'

import { showHelpState } from './data/recoilState'

import Modal from './Modal'

const Help: React.FC = () => {
  const [showHelp, setShowHelp] = useRecoilState(showHelpState)

  return (
    <Modal visible={showHelp} onDismiss={() => setShowHelp(false)}>
      <div className={styles.container}>
        <h2 className={styles.heading}>MTG Scratchpad by Lucky Paper</h2>
        <p>
          This scratchpad was intended for quickly creating annotated, enriched
          card lists to help in Magic content creation.
        </p>
        <p>
          Your list of cards is stored locally in your browser and isn&rsquo;t
          guaranteed to stick around forever. Copy your list to be safe!
        </p>
        <p>
          Questions or feedback? Find me on Twitter{' '}
          <a
            href="https://twitter.com/ahmattox"
            target="_blank"
            rel="noreferrer"
          >
            @ahmattox
          </a>
          .
        </p>
      </div>
    </Modal>
  )
}

export default Help
